/**
 * Modules configuration for core
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.modulesCfg');
new (function () {
	var path = 'core/modules';
	Object.assign(edi.modulesCfg, {
		'user.file.storage': {
			title: 'file.storage',
			modName: 'user.file.storage',
			folder: path,
			permissions: ['CLIENT_FILE_STORAGE']
		}
	});
})();
