import { createContainer } from '@UIkit/components/panels';

/**
 * Прогресс бар
 * @param {Object} progressBarSizes обязательный объект (width, height) для установления размеров самого прогресс бара
 * @param {Object} progressBarAmount обязательный объект (remainder, total) для расчетов прогресса в прогресс баре
 *
 *
 */
const createProgressBar = function (progressBarSizes, progressBarAmount) {
	progressBarSizes = progressBarSizes || {
		width: 276,
		height: 8
	};
	progressBarAmount = progressBarAmount || {
		remainder: 10,
		total: 50
	};
	let progressBarContainer, progressBarProgress;

	const getProgressBarProgressWidth = (progressBarAmount.remainder * 100) / progressBarAmount.total;
	const getProgressBarProgressWidthInPercentages = getProgressBarProgressWidth + '%';

	let progressBar = createContainer({
		cls: 'edi-ediweb-progress-bar',
		width: progressBarSizes.width,
		height: progressBarSizes.height,
		items: [
			(progressBarContainer = createContainer({
				height: progressBarSizes.height,
				cls: 'edi-ediweb-progress-bar-container',
				items: [
					(progressBarProgress = createContainer({
						height: progressBarSizes.height,
						cls: 'edi-ediweb-progress-bar-container-progress'
					}))
				]
			}))
		]
	});

	progressBarProgress.setWidth(getProgressBarProgressWidthInPercentages);

	return progressBar;
};

export { createProgressBar };
