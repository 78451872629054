import { createAddModulePanel } from '@Components/panels';
// @ts-ignore
import { createContainer } from '@UIkit/components/panels';
// @ts-ignore
import { createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { createPersonalInfoTab } from '@Core/modules/userProfile/tabs/personal.info';
import { createSettingsTab } from '@Core/modules/userProfile/tabs/settings';
import { createAuthorityAreasTab } from '@Core/modules/userProfile/tabs/authorityAreas/authority.areas';
import { createCertificatesTab } from './tabs/certificates';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';
// @ts-ignore
import { createButton } from '@UIkit/components/buttons';
import { utils } from '@App/js/utilities';
import { PersonalInfoTabProps, SaveSelfDataProps, SettingsTabProps, UserProfileFieldValues } from './definitions';
import { UserDetails } from '@App/js/definitions/user';
import { AsyncRequestResponseData } from '@App/js/definitions/request';
import { GridFilterObject } from '@App/js/definitions/grids';

export class UserProfileModule {
	[key: string]: any;

	moduleData: ModuleData;
	userData: UserDetails = {};
	confirmationData: PersonalInfoTabProps['confirmationData'] | null;
	additionalData: SettingsTabProps['additionalData'] = {};
	organizationData: AnyObject = {};
	activeTab: number = 0;
	tabCounter: number = 0;
	grids: {
		[gridName: string]: ExtComponent;
	} = {};
	tabs: {
		[tabName: string]: ExtComponent;
	} = {};
	forms: {
		[formName: string]: ExtComponent;
	};
	filterObjects: {
		[filterObjectName: string]: GridFilterObject | null;
	} = {};
	isMD: boolean = false;
	fields: {
		[fieldName: string]: ExtComponent;
	};
	_changeHandler: () => void;
	_reloadCertificates: () => void;

	constructor() {
		const me = this;
		me.userData = {};
		me.confirmationData = {};
		me.additionalData = {};
		me.organizationData = {};
		me.grids = {};
		me.tabs = {};
		me.forms = {};
		me.filterObjects = {};
		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._changeHandler = me.changeHandler.bind(this);
		me._reloadCertificates = me.reloadCertificates.bind(this);
	}

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data: ModuleData, initCallBack: ModuleInitCallback) {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.login.on('userChange', me._changeHandler);
		edi.events.certificate.on('change', me._reloadCertificates);
	}

	saveUserAuthSettings({
		thumbprint,
		authenticationChains = []
	}: {
		thumbprint: UserProfileFieldValues['thumbprint'];
		authenticationChains: UserProfileFieldValues['authenticationChains'];
	}): Promise<AsyncRequestResponseData> {
		const userAuthSettings = {
			thumbprint: thumbprint,
			authenticationChains: authenticationChains.map((chain) => ({
				method: [chain]
			}))
		};

		return edi.rest.asyncSendRequest({
			url: edi.rest.services.USER.SELF.CRD.AUTH.PUT,
			method: 'PUT',
			params: Ext.encode(userAuthSettings)
		});
	}

	getUserAuthSettings(): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.rest.services.USER.SELF.CRD.GET
		});
	}

	userSaveFailureHandler({ data }: { data: AnyObject }) {
		const me = this;
		edi.core.handleException(edi.utils.formatComplexServerError(data, 'User data did not saved properly'));
		edi.core.showError('error.user.save', () => me.setLoading(false));
	}

	saveSelfData(props: SaveSelfDataProps): Promise<AsyncRequestResponseData> {
		const userObj = {
			firstName: props.firstName,
			lastName: props.lastName,
			patronymicName: props.patronymicName,
			language: props.language,
			phones: props.phones,
			additionalPhone: props.additionalPhone,
			personalId: props.personalId,
			eMail: props.eMail
		};
		edi.utils.clearEmptyValues(userObj, false);
		return edi.rest.asyncSendRequest({
			url: edi.rest.services.USER.SELF.PUT,
			method: 'PUT',
			params: Ext.encode(userObj)
		});
	}

	createTabs(): { [tabName: string]: ExtComponent } {
		const me = this;
		const tabs: { [tabName: string]: ExtComponent } = {};

		tabs['personalInfo'] = createPersonalInfoTab(
			{
				data: me.userData,
				moduleInstance: me,
				isMD: me.isMD,
				confirmationData: me.confirmationData
			},
			{
				createSaveButton: () => me.createSaveButton()
			}
		);

		tabs['settings'] = createSettingsTab(
			{
				data: me.userData,
				moduleInstance: me,
				isMD: me.isMD,
				additionalData: me.additionalData
			},
			{
				createSaveButton: () => me.createSaveButton()
			}
		);
		edi.permissions.hasPermission('CLIENT_READ_EDI_SIGNER')
			? (tabs['authorityArea'] = createAuthorityAreasTab({
					moduleInstance: me
			  }))
			: null;
		edi.permissions.hasPermission('READ_CERTIFICATE_DOCUMENT')
			? (tabs['certificates'] = createCertificatesTab({ moduleInstance: me }))
			: null;

		for (let tab in tabs) {
			//Фильтр табов
			if (!tabs[tab]) {
				delete tabs[tab];
				continue;
			}

			const tabInstance = tabs[tab].tabInstance;
			if (tabInstance) {
				//Сохраняем гриды, фильтры и формы
				const { filterObject, grid, form } = tabInstance;
				if (filterObject) me.filterObjects[tab] = filterObject;
				if (grid) me.grids[tab] = grid;
				if (form) me.forms[tab] = form;
			}
		}

		return (me.tabs = tabs);
	}

	getTabs(): {
		[tabName: string]: ExtComponent;
	} {
		const me = this;
		return me.tabs ?? {};
	}

	setLoading(load: boolean | AnyObject | string) {
		const me = this;
		if (me.moduleData?.tab && !me.moduleData.tab.isDestroyed) {
			me.moduleData.tab.setLoading(load);
		}
	}

	getViewCls(): string | string[] {
		return 'edi-details-panel';
	}

	createView() {
		const me = this;
		const { personalInfo, settings, authorityArea, certificates } = me.createTabs();
		return createContainer({
			cls: me.getViewCls(),
			autoScroll: true,
			region: 'center',
			layout: 'fit',
			border: 0,
			items: createTabPanel({
				cls: [TAB_PANEL_CLS.simpleWithoutPadding, 'module-tabs', 'edi-additional-tbar'],
				items: [personalInfo, settings, authorityArea, certificates]
			})
		}) as ExtComponent;
	}

	async loadUserData() {
		const me = this;
		if (me.userData.userData) {
			edi.utils.setCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME, me.userData.language);
			me.additionalData = me.userData.userData.user || {};
			me.organizationData = me.userData.userData.organization || {};
		}

		if (edi.permissions.hasPermission('READ_USER_CREDENTIALS')) {
			await me.getUserAuthSettings().then(({ success, data: crdData }) => {
				if (crdData.success && crdData.data) {
					me.userData.thumbprint = crdData.data.thumbprint;
					me.userData.authenticationChains = crdData.data.authenticationChains.map(function (item: {
						creationDate: number;
						modifyDate: number;
						policy: string;
						id: number;
						method: string[];
						[key: string]: any;
					}) {
						return item.method && Array.isArray(item.method) && item.method.length ? item.method[0] : null;
					});
				}
			});
		}

		await me.checkMD().then(({ success, data }) => {
			if (success) me.isMD = data.data;
		});

		//Если пользователь связан с МД не дергаем client/user/mail_confirm_doc,
		if (!me.isMD) {
			await me.checkMailConfirm().then(({ success, data }) => {
				if (success) me.confirmationData = data.data;
			});
		}
	}

	loginGetUser(): Promise<{ userData: UserDetails | undefined }> {
		return new Promise((resolve) => {
			let userData: UserDetails | undefined;
			edi.login.getUser(
				() => resolve({ userData }),
				true,
				(data: UserDetails) => {
					userData = data;
				}
			);
		});
	}

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	async renderData(initCallBack?: ModuleInitCallback) {
		const me = this;
		me.setLoading(true);
		me.confirmationData = null;

		const { userData } = await me.loginGetUser();

		if (userData) {
			me.userData = userData;
			const selectedOrg = edi.core.getUserData().org;
			if (me.userData.orgs?.length) {
				for (let i = 0; i < me.userData.orgs.length; i++) {
					if (me.userData.orgs[i].id == selectedOrg.id) {
						me.userData.org = me.userData.orgs[i];
						break;
					}
				}
			}
		}

		await me.loadUserData();

		me.grids = {};
		me.tabCounter = 0;
		me.filterObjects = {};
		me.moduleData.tab.removeAll();

		const modulePanel = createAddModulePanel({
			autoScroll: false
		}) as ExtComponent;

		modulePanel.add(me.createView());
		me.moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			me.setLoading(false);
		}
	}

	checkMD(): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.rest.services.USER.SELF.CHECK_MD.GET
		});
	}

	checkMailConfirm(): Promise<AsyncRequestResponseData> {
		return edi.rest.asyncSendRequest({
			url: edi.rest.services.USER.EMAIL_CONFIRMATION.GET
		});
	}

	getForms() {
		const me = this;
		return me.forms;
	}

	getValues(): UserProfileFieldValues {
		const me = this;
		return Object.values(me.getForms()).reduce((allFormsValues, form) => {
			const formValues = edi.utils.collectFormValues(form, null, true);
			allFormsValues = { ...allFormsValues, ...formValues };
			return allFormsValues;
		}, {}) as UserProfileFieldValues;
	}

	getFields() {
		const me = this;
		if (!me.fields) {
			const tabsInstanceList = Object.values(me.getTabs())
				.map((tab) => tab.tabInstance)
				.filter(Boolean);
			let allFields = {};
			tabsInstanceList.forEach((_tabInstance) => {
				if (typeof _tabInstance.getFields === 'function') {
					const tabFields = _tabInstance.getFields() ?? {};
					allFields = { ...allFields, ...tabFields };
				}
			});
			me.fields = allFields;
		}

		return me.fields;
	}

	getFieldByName({ name }: { name: string }) {
		const me = this;

		if (!name) return;

		const allFields = me.getFields();
		return allFields[name] ?? Object.values(allFields).find((field) => field.name === name);
	}

	createSaveButton() {
		const me = this;
		return createButton({
			text: edi.i18n.getMessage('form.btn.save'),
			handler: function () {
				const formsIsValid = !Object.values(me.getForms()).some((form) => !form.isValid());
				const invalidField = utils.findAndFocusInvalidField({ fields: Object.values(me.getFields()) });
				if (formsIsValid && !invalidField) {
					me.saveHandler();
				}
			}
		}) as ExtComponent;
	}

	saveUserOrgAttributes({
		legacyDocAutoComplete
	}: {
		legacyDocAutoComplete: UserProfileFieldValues['autoComplete'];
	}): Promise<AsyncRequestResponseData> {
		const url = edi.utils.formatString(edi.rest.services.USER.ORGANIZATION.ATTRIBUTE.PUT, {
			name: 'legacyDocAutoComplete',
			value: String(!!legacyDocAutoComplete)
		});
		return edi.rest.asyncSendRequest({
			url,
			method: 'PUT',
			params: Ext.encode({})
		});
	}

	changeLanguageHandler({ language }: { language: UserProfileFieldValues['language'] }): Promise<{
		reloadPage: boolean;
		continueSave: boolean;
	}> {
		return new Promise((resolve, reject) => {
			const langChanged = language !== edi.utils.getCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME);
			if (langChanged) {
				showConfirm({
					title: edi.i18n.getMessage('user.profile.save.warning.title'),
					msgText: edi.i18n.getMessage('user.profile.save.warning'),
					success: () => resolve({ reloadPage: true, continueSave: true }),
					failure: () => resolve({ reloadPage: false, continueSave: false }),
					cancel: () => resolve({ reloadPage: false, continueSave: false })
				});
			} else {
				resolve({ reloadPage: false, continueSave: true });
			}
		});
	}

	convertValues({ values }: { values: UserProfileFieldValues }) {
		const userValues = Object.entries(values.userData.user);
		userValues.forEach(function ([key, val]) {
			let userKey = key as keyof UserProfileFieldValues['userData']['user'];
			values.userData.user[userKey] = (val === null ? '' : val) as any;
			if (userKey === 'saveFilter' || userKey === 'saveSort') {
				values.userData.user[userKey] = String(val);
			}
		});
		return values;
	}

	onSuccessSave({ isReloadPage, values }: { isReloadPage: boolean; values: UserProfileFieldValues }) {
		const me = this;
		const emailChanged = values.eMail && values.eMail !== me.userData.eMail;
		if (isReloadPage) {
			edi.utils.setCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME, values.language);
			document.location.reload();
		} else if (emailChanged) {
			me.changeHandler();
		} else {
			me.setLoading(false);
		}

		//если есть поле СНИЛС и заполнено делаем ему readOnly
		const snilsField = me.getFieldByName({ name: 'personalId' });
		if (snilsField && values.personalId) {
			snilsField.setReadOnly(true);
			snilsField.toolTip = Ext.create('Ext.tip.ToolTip', {
				target: snilsField.getEl()?.id,
				header: false,
				trackMouse: true,
				html: edi.i18n.getMessage('profile.snils.tooltip')
			});
		}
	}

	async saveHandler() {
		const me = this;

		me.setLoading(true);

		let values = me.getValues();
		values = me.convertValues({ values });

		const { reloadPage, continueSave } = await me.changeLanguageHandler({ language: values.language });

		if (!continueSave) {
			me.setLoading(false);
			return;
		}

		if (Ext.isDefined(values.autoComplete)) {
			const { success, data } = await me.saveUserOrgAttributes({
				legacyDocAutoComplete: values.autoComplete
			});

			if (!success) edi.rest.getErrorHandler()(data);
		}

		const { success: successSaveUser, data: savedUserData } = await me.saveUserAuthSettings({
			thumbprint: values.thumbprint,
			authenticationChains: values.authenticationChains || []
		});

		if (!successSaveUser) {
			me.userSaveFailureHandler({ data: savedUserData });
			return;
		}

		const { success: successSaveSelfData, data: savedSelfData } = await me.saveSelfData({
			personalId: edi.converters.SNILS.convertForSave(values.personalId),
			firstName: me.userData.firstName, // если не передавать ФИО в явном виде, поля очистятся,
			lastName: me.userData.lastName,
			patronymicName: me.userData.patronymicName,
			language: values.language,
			phones: values.phones,
			additionalPhone: values.additionalPhone,
			eMail: values.eMail
		});

		if (successSaveSelfData) {
			edi.core.updateUserData();
			edi.core.setExtraData(
				null,
				{
					user: values.userData?.user ?? {},
					organization: me.organizationData
				},
				() => edi.core.logMessage('User data saved', 'info')
			);
		} else {
			me.userSaveFailureHandler({ data: savedSelfData });
			return;
		}

		if (!successSaveSelfData) return;

		me.onSuccessSave({ values, isReloadPage: reloadPage });
	}

	changeHandler() {
		const me = this;
		me.renderData();
	}

	reloadCertificates() {
		const me = this;
		if (me.grids['certificates']) {
			me.grids['certificates'].getStore().reload();
		}
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy(): boolean {
		const me = this;
		edi.events.login.un('userChange', me._changeHandler);
		edi.events.certificate.un('change', me._reloadCertificates);
		let i;
		for (i in me.filterObjects) {
			if (me.filterObjects.hasOwnProperty(i) && me.filterObjects[i]?.searchTimeout) {
				clearTimeout(me.filterObjects[i]!.searchTimeout);
			}
		}
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name, null);
		return true;
	}
}

const USER_PROFILE_MODULE_NAME = 'user.profile';

Ext.namespace('edi.modulesCfg');
edi.modulesCfg[USER_PROFILE_MODULE_NAME] = {
	title: 'user.profile',
	modName: USER_PROFILE_MODULE_NAME,
	permissions: ['READ_USER_PROFILE']
};

Ext.namespace('edi.modules');
edi.modules[USER_PROFILE_MODULE_NAME] = UserProfileModule;
