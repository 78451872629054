const USER_FILE_STORAGE_COLUMN_NAME = 'user_file_storage';

edi.columns.addColumns({
	[USER_FILE_STORAGE_COLUMN_NAME]: {
		id: {
			text: 'column.id',
			dataIndex: 'id',
			flex: 1,
			hidden: true,
			sortable: false
		},
		guid: {
			text: 'column.guid',
			dataIndex: 'guid',
			flex: 1,
			hidden: true,
			sortable: false
		},
		docName: {
			text: 'column.doc.name',
			dataIndex: 'docName',
			flex: 2,
			sortable: true
		},
		contentType: {
			text: 'column.content.type',
			dataIndex: 'contentType',
			flex: 1,
			hidden: true,
			sortable: true
		},
		contentEncoding: {
			text: 'column.content.encoding',
			dataIndex: 'contentEncoding',
			flex: 1,
			hidden: true,
			sortable: true
		},
		docType: {
			text: 'column.doc.type',
			dataIndex: 'docType',
			flex: 2,
			sortable: true
		},
		byOrg: {
			text: 'column.by.org',
			dataIndex: 'byOrg',
			renderer: 'organization',
			flex: 2,
			sortable: true
		},
		toOrg: {
			text: 'documents.form.partner',
			dataIndex: 'toOrg',
			renderer: 'organization',
			flex: 2,
			sortable: true
		},
		byUser: {
			text: 'column.by.user',
			dataIndex: 'byUser',
			renderer: 'user',
			flex: 2,
			sortable: true
		},
		creationDate: {
			text: 'column.creation.date',
			dataIndex: 'creationDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			hidden: true,
			sortable: true
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			renderer: 'dateTimeFromMs',
			flex: 1,
			sortable: true
		},
		processed: {
			text: 'column.processed',
			dataIndex: 'processed',
			renderer: 'fileStorageProcessed',
			flex: 1,
			sortable: true
		},
		original: {
			text: 'column.original.doc.name',
			dataIndex: 'attrs',
			flex: 1,
			hidden: true,
			sortable: true,
			renderer: function (val, meta, record) {
				return val.docNameInTP || val.filename || val.original_name_s;
			}
		}
	}
});

let getUserFileStorageColumns = () => edi.columns.get(USER_FILE_STORAGE_COLUMN_NAME);

export { getUserFileStorageColumns, USER_FILE_STORAGE_COLUMN_NAME };
