// @ts-ignore
import { createButtonContainer, createContainer, createFieldBlock } from '@UIkit/components/panels';
import { createCombo, createTriggerField } from '@Components/fields';
import { createProxyConfig, createStore } from '@Components/storeComponents';
// @ts-ignore
import { createSwitch } from '@UIkit/components/fields';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createChangePasswordField } from '@Components/changePasswordForm';
// @ts-ignore
import { createTab } from '@UIkit/components/tab';
import { createFormForModule } from '@Components/panels';
// @ts-ignore
import { FIELD_BLOCK_CLS } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { SettingsTabProps } from '../definitions';
import { SendRequestResponse } from '@App/js/definitions/request';
import { Cert } from '@App/js/definitions/cert';
import { utils } from '@App/js/utilities';

export class SettingsTab {
	props: SettingsTabProps;
	fields: {
		[fieldName: string]: ExtComponent;
	};
	isEditAllowed: boolean;
	form: ExtComponent;

	constructor(props: SettingsTabProps, overrides?: Partial<SettingsTab>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: SettingsTabProps, overrides?: Partial<SettingsTab>) {
		const me = this;
		me.fields = {};
		me.isEditAllowed =
			edi.permissions.hasPermission('EDIT_USER_PROFILE') &&
			edi.permissions.hasPermission('EDIT_EDI_USER_PROFILE');
		Ext.merge(me, overrides);
		me.props = props;
	}

	createFieldWrapper(config = {}) {
		return createFieldBlock(
			Object.assign(
				{
					cls: FIELD_BLOCK_CLS.small
				},
				config
			)
		);
	}

	createLanguageField() {
		const me = this;
		const store = createStore({
			proxy: createProxyConfig({
				type: 'ajax',
				url: edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
					configuration_code: 'AVAILABLE_INTERFACE_LANGUAGES'
				}),
				reader: {
					rootProperty: 'data',
					transform: function (response: SendRequestResponse) {
						const availableLanguages = response.data ?? '';
						const resultLanguages = edi.stores.data.languages?.filter(
							(language: { id: string; name: string }) => availableLanguages.includes(language.id)
						);
						response.data = resultLanguages ?? [];
						return response;
					}
				}
			})
		});
		const language = (me.fields.language = createCombo({
			disabled: !me.isEditAllowed,
			forceSelection: true,
			editable: false,
			queryMode: 'local',
			store,
			name: 'language',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.language'),
			items: language
		});
	}

	createModuleStartField() {
		const me = this;
		const mdMain = edi.core.getMainModules();
		const types = [
			{
				id: null,
				name: edi.i18n.getMessage('form.combo.not.selected')
			}
		];
		for (let i = 0; i < mdMain.length; i++) {
			if (edi.permissions.hasPermissions(mdMain[i].permissions)) {
				types.push({
					id: mdMain[i].modName,
					name: edi.i18n.getMessage(mdMain[i].title)
				});
			}
		}
		const moduleStart = (me.fields.moduleStart = createCombo({
			disabled: !me.isEditAllowed,
			forceSelection: true,
			queryMode: 'local',
			store: edi.stores.createMemoryStore(types, 'SIMPLE'),
			name: 'userData.user.module',
			value: me.props.additionalData.module
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.module'),
			items: moduleStart
		});
	}

	createAutoCloseFilterPanelField() {
		const me = this;
		const closeTimeTypes: { id: string | null; name: string }[] = [
			{
				id: null,
				name: edi.i18n.getMessage('form.combo.not.selected')
			}
		];
		for (let i in edi.constants.DEFAULT.FILTER.CLOSE_TIMES) {
			if (edi.constants.DEFAULT.FILTER.CLOSE_TIMES.hasOwnProperty(i)) {
				closeTimeTypes.push({
					id: String(edi.constants.DEFAULT.FILTER.CLOSE_TIMES[i] * 1000),
					name: edi.utils.formatString(edi.i18n.getMessage('module.form.close.seconds'), {
						seconds: edi.constants.DEFAULT.FILTER.CLOSE_TIMES[i]
					})
				});
			}
		}
		const autoCloseFilterPanel = (me.fields.autoCloseFilterPanel = createCombo({
			disabled: !me.isEditAllowed,
			forceSelection: true,
			queryMode: 'local',
			store: edi.stores.createMemoryStore(closeTimeTypes, 'SIMPLE'),
			name: 'userData.user.autoCloseFilterPanel',
			value: me.props.additionalData.autoCloseFilterPanel
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.auto.close.filter.panel'),
			items: autoCloseFilterPanel
		});
	}

	createDefaultFilteringPeriodField() {
		const me = this;
		const periodTypes = [
			{
				id: null,
				name: edi.i18n.getMessage('form.combo.not.selected')
			}
		];
		for (let i in edi.constants.DEFAULT.FILTER.PERIODS) {
			if (edi.constants.DEFAULT.FILTER.PERIODS.hasOwnProperty(i)) {
				periodTypes.push({
					id: edi.constants.DEFAULT.FILTER.PERIODS[i],
					name: edi.i18n.getMessage('module.form.date.' + edi.constants.DEFAULT.FILTER.PERIODS[i])
				});
			}
		}
		const defaultFilteringPeriod = (me.fields.defaultFilteringPeriod = createCombo({
			disabled: !me.isEditAllowed,
			forceSelection: true,
			queryMode: 'local',
			store: edi.stores.createMemoryStore(periodTypes, 'SIMPLE'),
			name: 'userData.user.defaultFilteringPeriod',
			value: me.props.additionalData.defaultFilteringPeriod
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.filter.time'),
			items: defaultFilteringPeriod
		});
	}

	createAuthenticationMethodField() {
		const me = this;
		const permissionPassed = edi.permissions.hasPermission('READ_USER_CREDENTIALS');
		if (me.props.isMD && permissionPassed) {
			const authenticationMethod = (me.fields.authenticationChains = createCombo({
				store: edi.stores.createMemoryStore(
					[
						{
							id: 'HTTP_LOGIN',
							name: edi.i18n.getMessage('column.crd.login.password')
						},
						{
							id: 'CERTIFICATE',
							name: edi.i18n.getMessage('certificate.title')
						}
					],
					'SIMPLE'
				),
				multiSelect: true,
				name: 'authenticationChains',
				valueSrc: me.props.data,
				listeners: {
					select: function (combo: ExtComponent, records: ExtRecord<{ id: string; name: string }>) {
						const thumbprintField = me.fields.thumbprint;
						if (records && Array.isArray(records) && thumbprintField) {
							thumbprintField.allowBlank = records.every(function (item) {
								return item.getId() !== 'CERTIFICATE';
							});
						}
						me.getForm().isValid();
					}
				}
			}));
			return me.createFieldWrapper({
				title: edi.i18n.getMessage('user.crd.method.auth'),
				items: authenticationMethod
			});
		}
	}

	createThumbprintField() {
		const me = this;
		const permissionPassed = edi.permissions.hasPermission('READ_USER_CREDENTIALS');
		if (me.props.isMD && permissionPassed) {
			const allowBlank =
				me.props.data.authenticationChains && Array.isArray(me.props.data.authenticationChains)
					? me.props.data.authenticationChains.every(function (item) {
							return item !== 'CERTIFICATE';
					  })
					: true;
			const thumbprint = (me.fields.thumbprint = createTriggerField({
				allowBlank: allowBlank,
				name: 'thumbprint',
				editable: false,
				valueSrc: me.props.data,
				triggers: {
					cancel: {
						extraCls: 'edi-icon edi-icon-CANCEL',
						handler: function () {
							thumbprint.setValue('');
						}
					},
					add: {
						extraCls: 'edi-icon edi-icon-ADD',
						handler() {
							edi.sign.selectCertificate(
								function (data: Cert) {
									if (data && data.Thumbprint) {
										thumbprint.setValue(data.Thumbprint);
									}
								},
								undefined,
								true
							);
						}
					}
				}
			}));
			return me.createFieldWrapper({
				title: edi.i18n.getMessage('column.thumbprint'),
				items: thumbprint
			});
		}
	}

	createDoNotUseNotificationDialogActCertPoaField() {
		const me = this;
		return (me.fields.doNotUseNotificationDialogActCertPoa = createSwitch({
			boxLabel: edi.i18n.getMessage('user.profile.doNotUseNotificationDialogActCertPoa'),
			name: 'userData.user.doNotUseNotificationDialogActCertPoa',
			hidden: !edi.permissions.hasPermission('EDIT_USER_PROFILE'),
			checked:
				edi.utils.getObjectProperty(me.props.additionalData, 'doNotUseNotificationDialogActCertPoa') === 'true'
		}));
	}

	createDefaultFilteringField() {
		const me = this;
		const fields: { id: string | null; name: string }[] = [
			{
				id: null,
				name: edi.i18n.getMessage('form.combo.not.selected')
			}
		];
		Object.entries(edi.constants.DEFAULT.FILTER.FIELDS || {}).forEach(([key, val]) => {
			fields.push({
				id: key,
				name: edi.i18n.getMessage('user.profile.filter.field.' + val)
			});
		});
		const defaultFilteringField = (me.fields.defaultFilteringField = createCombo({
			disabled: !(
				edi.permissions.hasPermission('EDIT_USER_PROFILE') &&
				edi.permissions.hasPermission('EDIT_EDI_USER_PROFILE')
			),
			store: edi.stores.createMemoryStore(fields, 'SIMPLE'),
			name: 'userData.user.defaultFilteringField',
			value: me.props.additionalData.defaultFilteringField
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.filter.field'),
			items: defaultFilteringField
		});
	}

	createSortingDocumentsByDefaultField() {
		const me = this;
		const sortingDocumentsByDefault = (me.fields.defaultSorting = createCombo({
			disabled: !me.isEditAllowed,
			forceSelection: true,
			queryMode: 'local',
			store: edi.stores.createSimpleInlineStore(
				['creationDate', 'modifyDate', 'doctime'],
				function (id: string) {
					return edi.i18n.getMessage('sorting.documents.by.default.' + id);
				},
				true
			),
			name: 'userData.user.defaultSorting',
			valueSrc: me.props.data
		}));
		return me.createFieldWrapper({
			title: edi.i18n.getMessage('user.profile.sorting.documents.by.default'),
			items: sortingDocumentsByDefault
		});
	}

	createSaveSortField() {
		const me = this;
		return (me.fields.saveSort = createSwitch({
			boxLabel: edi.i18n.getMessage('user.profile.saveSort'),
			hidden: !edi.permissions.hasPermission('EDIT_USER_PROFILE'),
			name: 'userData.user.saveSort',
			checked: edi.utils.getObjectProperty(me.props.additionalData, 'saveSort') === 'true'
		}));
	}

	createSaveFilterField() {
		const me = this;
		return (me.fields.saveFilter = createSwitch({
			boxLabel: edi.i18n.getMessage('user.profile.saveFilter'),
			hidden: !edi.permissions.hasPermission('EDIT_USER_PROFILE'),
			name: 'userData.user.saveFilter',
			checked: edi.utils.getObjectProperty(me.props.additionalData, 'saveFilter') === 'true'
		}));
	}

	createAutoCompleteField() {
		const me = this;
		return (me.fields.autoComplete = createSwitch({
			boxLabel: edi.i18n.getMessage('user.profile.auto.complete'),
			hidden: !edi.permissions.hasPermission('USER_ORG_DOCUMENT_AUTOCOMPLETE'),
			name: 'autoComplete',
			checked: edi.utils.getObjectProperty(me.props.data.org, 'attributes.legacyDocAutoComplete.value') === 'true'
		}));
	}

	createClearFiltersSettingsButton() {
		const isAllow =
			edi.constants.RESTORE_FILTER_VALUES_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE');
		if (isAllow) {
			return createButton({
				text: edi.i18n.getMessage('action.clear.filters.settings'),
				cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
				handler: function () {
					edi.core.confirm(null, 'filters.settings.restore.confirmation', function () {
						edi.utils.setData('filters', '');
						edi.utils.setData('sorters', '');
						edi.core.showInfo('filters.settings.restore.success');
					});
				}
			});
		}
	}

	createClearColumnSettingsButton() {
		const isAllow = edi.constants.COLUMN_CONFIG_SAVE_ENABLED && edi.permissions.hasPermission('EDIT_USER_PROFILE');
		if (isAllow) {
			return createButton({
				text: edi.i18n.getMessage('action.clear.column.settings'),
				cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
				handler: function () {
					edi.core.confirm(null, 'columns.settings.restore.confirmation', function () {
						edi.utils.setData('c', '');
						edi.core.showInfo('columns.settings.restore.success');
					});
				}
			});
		}
	}

	createChangePasswordButton() {
		const updateUserPassword = function (oldPassword: string, newPassword: string) {
			const success = function () {
				edi.core.logMessage('User password saved', 'info');
				document.location.reload();
			};
			const failure = function (data: SendRequestResponse) {
				edi.core.handleException('User password did not saved properly');
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			};
			edi.rest.sendRequest(
				edi.rest.services.USER.SELF.CRD.PUT,
				'PUT',
				Ext.encode({
					oldPassword: oldPassword,
					newPassword: newPassword
				}),
				success,
				failure
			);
		};
		return createChangePasswordField({
			onlyButton: true,
			onPasswordSave: ({
				password
			}: {
				password: {
					old: string;
					new: string;
					confirmNew: string;
				};
			}) => {
				updateUserPassword(password.old, password['new']);
			}
		});
	}

	saveHandler() {}

	getForm() {
		const me = this;
		return me.form;
	}

	getValues() {
		const me = this;
		return edi.utils.collectFormValues(me.form, null, true);
	}

	createSaveButton() {
		const me = this;
		return createButton({
			text: edi.i18n.getMessage('form.btn.save'),
			handler: function () {
				const formIsValid = me.getForm().isValid();
				const invalidField = utils.findAndFocusInvalidField({ fields: Object.values(me.getFields()) });
				if (formIsValid && !invalidField) {
					me.saveHandler();
				}
			}
		});
	}

	getFields() {
		const me = this;
		return me.fields ?? {};
	}

	getFieldByName({ name }: { name: string }) {
		const me = this;

		if (!name) return;

		const allFields = me.getFields();
		return allFields[name] ?? Object.values(allFields).find((field) => field.name === name);
	}

	getFormCls(): string | string[] {
		return 'edi-details-panel';
	}

	createView() {
		const me = this;
		const language = me.createLanguageField();
		const moduleStart = me.createModuleStartField();
		const autoCloseFilterPanel = me.createAutoCloseFilterPanelField();
		const defaultFilteringPeriod = me.createDefaultFilteringPeriodField();
		const authenticationMethod = me.createAuthenticationMethodField();
		const thumbprint = me.createThumbprintField();
		const doNotUseNotificationDialogActCertPoa = me.createDoNotUseNotificationDialogActCertPoaField();
		const defaultFilteringField = me.createDefaultFilteringField();
		const sortingDocumentsByDefault = me.createSortingDocumentsByDefaultField();
		const saveSort = me.createSaveSortField();
		const saveFilter = me.createSaveFilterField();
		const autoComplete = me.createAutoCompleteField();
		const clearFiltersSettingsBtn = me.createClearFiltersSettingsButton();
		const clearColumnSettingsBtn = me.createClearColumnSettingsButton();
		const changePasswordBtn = me.createChangePasswordButton();

		const firstColumn = [
			language,
			moduleStart,
			sortingDocumentsByDefault,
			autoCloseFilterPanel,
			defaultFilteringPeriod,
			defaultFilteringField,
			changePasswordBtn
		].filter(Boolean);
		const secondColumn = [
			authenticationMethod,
			thumbprint,
			createContainer({
				layout: {
					type: 'grid',
					gap: 16
				},
				items: [saveFilter, saveSort]
			}),
			createContainer({
				layout: {
					type: 'grid',
					gap: 16
				},
				items: [autoComplete, doNotUseNotificationDialogActCertPoa]
			}),
			clearFiltersSettingsBtn,
			clearColumnSettingsBtn
		].filter(Boolean);
		return (me.form = createFormForModule({
			cls: me.getFormCls(),
			layout: {
				type: 'grid',
				area: [[3, 1, 6]]
			},
			items: [
				createContainer({
					layout: {
						type: 'grid',
						gap: 24,
						area: [12, 12, 12, 12, 12, 12, 6]
					},
					items: firstColumn
				}),
				// Костыль для layout: grid, чтобы между полями была пустая колонка
				createContainer({
					hidden: true
				}),
				createContainer({
					layout: {
						type: 'grid',
						gap: 24,
						// Динамическая area, т.к. authenticationMethod и thumbprint может не быть
						// последний элемент имеет ширину 4 колонки, остальные 6
						area: secondColumn?.length === 4 ? [6, 10, 6, 4] : [6, 6, 6, 10, 6, 4]
					},
					items: secondColumn
				})
			],
			buttons: me.isEditAllowed
				? createButtonContainer({
						items: me.createSaveButton()
				  })
				: undefined
		}));
	}

	createTab() {
		const me = this;
		return createTab({
			title: edi.i18n.getMessage('user.profile.tab.settings'),
			closable: false,
			panelType: 'tabpanel',
			tabInstance: me,
			items: me.createView()
		});
	}
}

export const createSettingsTab = function (props: SettingsTabProps, overrides?: Partial<SettingsTab>): ExtComponent {
	const settings = new SettingsTab(props, overrides);
	return settings.createTab() as ExtComponent;
};
