import '@UIkit/components/fields/Combobox/Combobox';

import infoTpl from './ComboboxCerts.ext.tpl';
import './ComboboxCerts.scss';
import './models';

const baseCls = 'ui-comboboxfield-certs';

Ext.define('UI.components.ComboboxCertsField', {
	extend: 'UI.components.ComboboxField',
	baseCls,
	descCls: `${baseCls}-desc`,

	displayField: 'display_value',
	name: 'cert',
	valueField: 'cert',
	queryCaching: true,
	lastQuery: '',
	store: edi.stores.createInlineStore([], 'CERTIFICATE'),

	initComponent: function () {
		const me = this;
		me.callParent();
		me.on(
			'expand',
			function (comp) {
				const comboStore = comp.getStore();
				if (edi.sign.isAvailable() && !comboStore.getCount()) {
					comp.picker.setLoading(true);
					edi.sign.getCertificates({
						callback: function (data) {
							comboStore.loadData(edi.utils.parseCertificateData(data.data));
							comp.picker.setLoading(false);
						}
					});
				}
			},
			undefined,
			{
				single: true
			}
		);
	},

	beforeInit: function () {
		const me = this;

		if (!me.listConfig) {
			me.listConfig = {};
		}

		me.listConfig.onMaskHide = function () {
			var me = this;
			if (!me.destroying && me.hasLoadingHeight) {
				me.minHeight = me.oldMinHeight;
				// убрала updateLayout т.к. он вызывает event viewlist
				//из-за этого в темплейт еще раз добавляются данные
				//me.updateLayout();
				delete me.hasLoadingHeight;
			}
		};
	},

	afterRender: function () {
		const me = this,
			picker = me.getPicker();

		me.callParent();

		picker.on('viewlist', function (__self, record, itemEl) {
			const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();
			const tpl = new Ext.XTemplate(infoTpl);
			tpl.$comp = me;
			tpl.append(wrapEl, record.getData());
		});
	}
});

const createComboCerts = (config) => new UI.components.ComboboxCertsField(config);

export { createComboCerts };
