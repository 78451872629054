import { DOCS_WITH_BUSINESS_STATE } from '@Edi/configuration';

export type DocType = string;
export type DocState = string;
export type ActionName = string;

export interface ActionRuleCheckOptions extends AnyObject {
	docType: DocType;
	state: DocState;
	direction: string;
	needSignatures: number;
	hasPart2: boolean;
	record: ExtRecord<AnyObject>;
	attributes?: AnyObject;
	parent?: AnyObject;
	utochNotFinished?: boolean;
	annulStatus?: string | null;
	annulNotActive?: boolean;
	isUnsupportedDSF?: boolean;
}

export interface ActionRuleDirectionConfig {
	DOCUMENTS?: (DocType | DocType[] | { DOCUMENT: DocType; STATE?: DocState[]; BUSINESS_STATE?: DocState[] })[];
	STATE?: (DocState | DocState[])[];
	BUSINESS_STATE?: (DocState | DocState[])[];
	SINGLE_APPLY?: {
		DOCUMENT: DocType;
		STATE?: DocState[];
		BUSINESS_STATE?: DocState[];
		ALL_STATE?: boolean;
		ALL_BUSINESS_STATE?: boolean;
	}[];
}

export interface ActionRuleConditionBase {
	directions?: string[];
	documents?: (DocType | DocType[])[];
	states?: (DocState | DocState[])[];
	businessState?: (DocState | DocState[])[];
	customMethod?: (checkOptions: ActionRuleCheckOptions) => boolean;
}

export interface ActionRuleCondition extends ActionRuleConditionBase {
	allow?: boolean | ((checkOptions: ActionRuleCheckOptions) => boolean);
}

export interface ActionRulePermissionChange extends ActionRuleConditionBase {
	change: (actionPermission: string, checkOptions: ActionRuleCheckOptions) => string;
}

export interface ActionRule {
	OUTGOING?: ActionRuleDirectionConfig;
	INCOMING?: ActionRuleDirectionConfig;
	LOOP?: ActionRuleDirectionConfig;
	INCOMING_FACTOR?: ActionRuleDirectionConfig;
	UNKNOWN?: ActionRuleDirectionConfig;
	conditions?: ActionRuleCondition[];
	permissionChanging?: ActionRulePermissionChange[];
}

export interface ActionRules {
	[key: ActionName]: ActionRule;
}

Ext.namespace('edi.action');
edi.action = {
	//это правила только для конкретного docType (должны находиться в папке модуля)
	rulesByDocType: {} as { [key: DocType]: ActionRules },
	//массив функций для расширения результата метода getDocumentData
	checkOptionProcessings: [],
	//проверяем по businessState только переведенные на них документы из DOCS_WITH_BUSINESS_STATE
	isUseBusinessState: (docType?: string): boolean => {
		return DOCS_WITH_BUSINESS_STATE.some((businessDocType) => businessDocType === docType);
	},

	/**
	 * Search for item in (maybe nested) array
	 */
	findItemInNestedArrays<T = string>(arr: (T | T[])[], itemForSearch: T): boolean {
		let result = false;
		if (Array.isArray(arr)) {
			for (let i = 0; i < arr.length; i++) {
				let item = arr[i];
				if (item === itemForSearch) {
					result = true;
					break;
				} else if (Array.isArray(item) && item.some((it) => it === itemForSearch)) {
					result = true;
					break;
				}
			}
		}
		return result;
	},
	/**
	 * Checks if action rule is available
	 */
	checkRuleAvailable(rule: ActionRuleDirectionConfig, doctype: string, docstate: string): boolean {
		if (!rule) {
			return false;
		}

		let available = false;
		let singleRuleFound = false;

		if (rule.SINGLE_APPLY && Array.isArray(rule.SINGLE_APPLY)) {
			for (let i = 0; i < rule.SINGLE_APPLY.length; i++) {
				if (rule.SINGLE_APPLY[i].DOCUMENT === doctype) {
					const states = rule.SINGLE_APPLY[i].STATE;
					if (Array.isArray(states) && states.some((it) => it === docstate)) {
						available = true;
					} else if (rule.SINGLE_APPLY[i].ALL_STATE) {
						available = true;
					}
					singleRuleFound = true;
					break;
				}
			}
		}

		if (!singleRuleFound) {
			//массивы DOCUMENTS и\или STATE могут быть вложенные
			if (
				(!rule.DOCUMENTS || edi.action.findItemInNestedArrays(rule.DOCUMENTS, doctype)) &&
				(!rule.STATE || edi.action.findItemInNestedArrays(rule.STATE, docstate))
			) {
				available = true;
			}
			//поищем еще в конфиге DOCUMENTS объекты вида {DOCUMENT: string, STATE: string[]}
			else if (rule.DOCUMENTS && Array.isArray(rule.DOCUMENTS)) {
				for (let i = 0; i < rule.DOCUMENTS.length; i++) {
					let item = rule.DOCUMENTS[i];
					if (
						'object' == typeof item &&
						item !== null &&
						!Array.isArray(item) &&
						item.DOCUMENT === doctype &&
						Array.isArray(item.STATE) &&
						item.STATE.some((it) => it === docstate)
					) {
						available = true;
						break;
					}
				}
			}
		}
		return available;
	},

	/**
	 * Checks if action rule is available
	 */
	checkRuleAvailableForBusinessState(rule: ActionRuleDirectionConfig, checkOptions: ActionRuleCheckOptions): boolean {
		if (!rule) {
			return false;
		}
		const { docType, businessState } = checkOptions || {};
		let available = false;
		let singleRuleFound = false;

		if (rule.SINGLE_APPLY && Array.isArray(rule.SINGLE_APPLY)) {
			for (let i = 0; i < rule.SINGLE_APPLY.length; i++) {
				if (rule.SINGLE_APPLY[i].DOCUMENT === docType) {
					const states = rule.SINGLE_APPLY[i].BUSINESS_STATE;
					if (Array.isArray(states) && states.some((it) => it === businessState)) {
						available = true;
					} else if (rule.SINGLE_APPLY[i].ALL_BUSINESS_STATE) {
						available = true;
					}
					singleRuleFound = true;
					break;
				}
			}
		}

		if (!singleRuleFound) {
			//массивы DOCUMENTS и\или BUSINESS_STATE могут быть вложенные
			if (
				(!rule.DOCUMENTS || edi.action.findItemInNestedArrays(rule.DOCUMENTS, docType)) &&
				(!rule.BUSINESS_STATE || edi.action.findItemInNestedArrays(rule.BUSINESS_STATE, businessState))
			) {
				available = true;
			}
			//поищем еще в конфиге DOCUMENTS объекты вида {DOCUMENT: string, BUSINESS_STATE: string[]}
			else if (rule.DOCUMENTS && Array.isArray(rule.DOCUMENTS)) {
				for (let i = 0; i < rule.DOCUMENTS.length; i++) {
					let item = rule.DOCUMENTS[i];
					if (
						'object' == typeof item &&
						item !== null &&
						!Array.isArray(item) &&
						item.DOCUMENT === docType &&
						Array.isArray(item.BUSINESS_STATE) &&
						item.BUSINESS_STATE.some((it) => it === businessState)
					) {
						available = true;
						break;
					}
				}
			}
		}
		return available;
	},

	checkAdditionalConditions(data: ActionRuleConditionBase, checkOptions: ActionRuleCheckOptions) {
		var isDirection = true,
			isDocType = true,
			isState = true,
			isCustomMethod = true;
		var mergeCollection = function <T = string>(collection: (T | T[])[]): T[] {
			var mergedCollection: T[] = [];
			for (var i = 0; i < collection.length; i++) {
				mergedCollection = mergedCollection.concat(collection[i]);
			}
			return mergedCollection;
		};

		if (Array.isArray(data.directions)) {
			isDirection = data.directions.some((it) => it === checkOptions.direction);
		}
		if (Array.isArray(data.documents)) {
			isDocType = mergeCollection(data.documents).some((it) => it === checkOptions.docType);
		}
		if (Array.isArray(data.states)) {
			isState = mergeCollection(data.states).some((it) => it === checkOptions.state);
		}
		if ('function' == typeof data.customMethod) {
			isCustomMethod = data.customMethod(checkOptions);
		}

		return isDirection && isDocType && isState && isCustomMethod;
	},

	/**
	 * Method for overlapping for get default action rule
	 */
	defaultActionRule(action: string, checkOptions: ActionRuleCheckOptions) {
		return (
			!(
				checkOptions.needSignatures &&
				(edi.constants.DOCUMENT_ACTIONS?.COMPLETE === action || edi.constants.DOCUMENT_ACTIONS?.SEND === action)
			) && checkOptions.annulNotActive
		);
	},

	/**
	 * Check is available action
	 */
	isAvailable(action: string, checkOptions: ActionRuleCheckOptions): boolean {
		return this.isAvailableExtended(action, checkOptions)['result'];
	},

	/**
	 * Check is available action and return extended output
	 */
	isAvailableExtended(action: string, checkOptions: ActionRuleCheckOptions) {
		let me = this;
		let actionPermission: string | null = null;
		let permissionsPassed = false;
		let rulePassed = false;
		let conditionsPassed = false;

		try {
			let newRulesByDocType: ActionRules | undefined = me.rulesByDocType[checkOptions.docType];
			let actionRules: ActionRule | undefined = newRulesByDocType?.[action];

			//--- PERMISSIONS ---
			actionPermission =
				action && checkOptions.docType && edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP[action]
					? edi.constants.DOCUMENT_ACTIONS_PERMISSIONS_CHECK_MAP[action] + '_' + checkOptions.docType
					: null;
			let permissionsChangingArray = actionRules?.permissionChanging || [];
			let changeRule = permissionsChangingArray.find(function (permissionsChangingObj) {
				return me.checkAdditionalConditions(permissionsChangingObj, checkOptions);
			});
			if (
				actionPermission &&
				changeRule &&
				me.checkAdditionalConditions(changeRule, checkOptions) &&
				changeRule.hasOwnProperty('change')
			) {
				actionPermission =
					'function' == typeof changeRule.change
						? changeRule.change(actionPermission, checkOptions)
						: actionPermission;
			}
			permissionsPassed = !actionPermission || edi.permissions.hasPermission(actionPermission);

			//--- RULE ---
			const dir = checkOptions.direction as keyof ActionRule;
			let rule = actionRules?.[dir] as ActionRuleDirectionConfig | undefined;
			rulePassed =
				rule && me.isUseBusinessState(checkOptions.docType)
					? edi.action.checkRuleAvailableForBusinessState(rule, checkOptions)
					: edi.action.checkRuleAvailable(rule, checkOptions.docType, checkOptions.state);

			//--- CONDITIONS ---
			if (permissionsPassed && rulePassed) {
				let complexConfig = actionRules?.conditions || [];
				let conf = complexConfig.find((c) => me.checkAdditionalConditions(c, checkOptions));
				if (conf) {
					conditionsPassed = !conf.hasOwnProperty('allow')
						? true
						: 'function' == typeof conf.allow
						? !!conf.allow(checkOptions)
						: !!conf.allow;
				} else {
					conditionsPassed = me.defaultActionRule(action, checkOptions);
				}
			}
		} catch (err) {
			edi.core.logMessage(
				`Error while checking action=${action} for docType=${checkOptions?.docType} docId=${checkOptions?.record?.data?.id}`,
				'error'
			);
			console.error(err);
		}

		return {
			result: permissionsPassed && rulePassed && conditionsPassed,
			permissionsPassed,
			actionPermission,
			rulePassed,
			conditionsPassed
		};
	},

	/**
	 * Get document data object for check rule actions
	 */
	getDocumentData(record: ExtRecord<AnyObject>, parent?: AnyObject, options?: AnyObject): ActionRuleCheckOptions {
		options = options ? options : {};
		var state = record.get('state'),
			type = record.get('type'),
			toOrg = record.get('toOrg'),
			fromOrg = record.get('fromOrg'),
			factor = record.get('factor'),
			direction = edi.utils.getDocumentDirection(toOrg, fromOrg, factor),
			needSignatures = record.get('needSignatures'),
			signaturesNeeded,
			countSignatures = record.get('countSignatures'),
			signaturesMade = edi.document.actions.getSignMadeCount(record.getData()),
			hasPart2 = record.get('hasPart2');
		const businessState = record.get('businessState');
		if (
			Ext.isNumber(signaturesMade) ||
			record.get('type') === edi.constants.DOCUMENT_TYPES.FP_CONVENTION_AGREEMENT_TERMINATION
		) {
			signaturesNeeded = needSignatures - (signaturesMade || 0);
		} else {
			var needSignatures2 = 0;
			if (
				edi.constants.DIRECTIONS.INCOMING === direction ||
				edi.constants.DIRECTIONS.INCOMING_FACTOR === direction
			) {
				var side = edi.document.actions.getSignSide(type, edi.constants.DIRECTIONS.OUTGOING);
				needSignatures2 = edi.document.actions.getSignCount(record.getData(), side);
			}
			signaturesNeeded = needSignatures + needSignatures2 - countSignatures;
			signaturesNeeded = signaturesNeeded < 0 ? 0 : signaturesNeeded;
		}
		var resultOptions = {
			docType: type,
			state: state,
			businessState,
			direction: direction,
			needSignatures: signaturesNeeded,
			hasPart2: hasPart2,
			record: record,
			attributes: record.get('attributes'),
			parent: parent
		};
		for (var i = 0; i < this.checkOptionProcessings.length; i++) {
			if ('function' == typeof this.checkOptionProcessings[i]) {
				resultOptions = Ext.merge(resultOptions, this.checkOptionProcessings[i](resultOptions));
			}
		}
		return Ext.applyIf(options, resultOptions) as ActionRuleCheckOptions;
	}
};
