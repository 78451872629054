import { CertificatesGridRecordData } from '@Core/modules/userProfile/definitions';

const USER_CERTIFICATES_COLUMN_CONFIG_NAME = 'certificates';
const SIMPLE_NAME_COLUMN_CONFIG_NAME = 'simple_name';

edi.columns.addColumns({
	[USER_CERTIFICATES_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'documents.column.id',
			sortable: false,
			flex: 1,
			hidden: true,
			dataIndex: 'id'
		},
		serialNumber: {
			text: 'documents.column.cert.number',
			sortable: false,
			flex: 2,
			hidden: true,
			renderer: 'valueAsToolTip',
			dataIndex: 'SerialNumber'
		},
		subjectName: {
			text: 'column.certificate.subject',
			sortable: false,
			flex: 2,
			renderer: 'certificateSubjectString',
			dataIndex: 'SubjectName'
		},
		issuerName: {
			text: 'column.certificate.issuer',
			sortable: false,
			flex: 2,
			renderer: 'certificateIssuerString',
			dataIndex: 'IssuerName'
		},
		availableDocTypesForSignature: {
			text: 'column.certificate.documents',
			sortable: false,
			flex: 2,
			renderer: 'certificateDocTypes',
			dataIndex: 'availableDocTypesForSignature'
		},
		validFromDate: {
			text: 'column.valid.from.date',
			dataIndex: 'ValidFromDate',
			flex: 1,
			sortable: false,
			renderer: 'dateFromMs'
		},
		validToDate: {
			text: 'column.valid.to.date',
			dataIndex: 'ValidToDate',
			flex: 1,
			sortable: false,
			renderer: 'dateFromMs'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		},
		algorithm: {
			text: 'column.certificate.algorithm',
			hidden: true,
			dataIndex: 'algorithm',
			renderer: function (val: CertificatesGridRecordData['algorithm']) {
				return val ? val.oid : '';
			}
		},
		state: {
			text: 'documents.column.cert.status',
			dataIndex: 'state',
			renderer: 'certificateStatus',
			flex: 1,
			htmlEncode: true
		}
	},
	[SIMPLE_NAME_COLUMN_CONFIG_NAME]: {
		name: {
			text: 'column.name',
			dataIndex: 'name',
			flex: 2,
			hideable: false
		}
	}
});

const getUserCertificatesColumns = () => edi.columns.get(USER_CERTIFICATES_COLUMN_CONFIG_NAME);
const getSimpleNameColumns = () => edi.columns.get(SIMPLE_NAME_COLUMN_CONFIG_NAME);

export {
	getUserCertificatesColumns,
	USER_CERTIFICATES_COLUMN_CONFIG_NAME,
	getSimpleNameColumns,
	SIMPLE_NAME_COLUMN_CONFIG_NAME
};
