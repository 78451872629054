const USER_SIGNER_SETTING_MODEL_CONFIG_NAME = 'USER_SIGNER_SETTING';

edi.models.setModels({
	[USER_SIGNER_SETTING_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'doctype',
				type: 'string'
			},
			{
				name: 'medianDuration',
				type: 'number'
			}
		]
	}
});

const getUserSignerSettingModelName = () => edi.models.getModel(USER_SIGNER_SETTING_MODEL_CONFIG_NAME);

export { getUserSignerSettingModelName, USER_SIGNER_SETTING_MODEL_CONFIG_NAME };
