import { AuthorityAreasGridRecordData } from '@Core/modules/userProfile/definitions';

const USER_SIGNER_SETTINGS_COLUMN_CONFIG_NAME = 'user_signer_settings';

edi.columns.addColumns({
	[USER_SIGNER_SETTINGS_COLUMN_CONFIG_NAME]: {
		docType: {
			text: 'document.type',
			tdCls: 'x-grid-cell-inner-portable-row',
			sortable: false,
			flex: 1,
			dataIndex: 'docType',
			renderer: function (value: string) {
				return value ? edi.i18n.getMessage('documents.doctype.' + value) : '';
			}
		},
		direction: {
			text: 'column.direction',
			tdCls: 'x-grid-cell-inner-portable-row',
			sortable: false,
			flex: 1,
			dataIndex: 'direction',
			renderer: function (value: string) {
				return edi.i18n.getMessage('user.profile.signer.direction.' + value);
			}
		},
		authorityArea: {
			text: 'documents.fns_upd.signer.authorityArea',
			sortable: false,
			flex: 3,
			dataIndex: 'authorityArea',
			renderer: function (value: string, meta: AnyObject, record: AuthorityAreasGridRecordData) {
				let direction = record.get('direction');
				let items: { id: number; name: string }[] =
					record.get('docType') === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2AuthorityAreas[direction]
						: edi.stores.data.fullAuthorityAreas;

				let translation = items.filter((it) => +it.id === +value)[0]?.name;
				return value && translation ? `${value} - ${translation}` : value || '';
			}
		},
		state: {
			text: 'documents.fns_upd.signer.status',
			dataIndex: 'state',
			flex: 3,
			sortable: false,
			renderer: function (value: string, meta: AnyObject, record: AuthorityAreasGridRecordData) {
				let direction = record.get('direction');
				let items: { id: number; name: string }[] =
					record.get('docType') === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2SignerStatuses[direction]
						: direction === 'OUTBOX'
						? edi.stores.data.signerBuyerUPD501NStatuses
						: edi.stores.data.signerBuyerStatuses;

				let translation = items.filter((it) => +it.id === +value)[0]?.name;
				return value && translation ? `${value} - ${translation}` : value || '';
			}
		},
		baseAuthory: {
			text: 'documents.fns_upd.signer.baseAuthory',
			sortable: false,
			flex: 1,
			dataIndex: 'baseAuthory'
		},
		baseOrgAuthory: {
			text: 'documents.fns_upd.signer.baseOrgAuthory',
			sortable: false,
			flex: 1,
			dataIndex: 'baseOrgAuthory'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs'
		}
	}
});

const getUserSignerSettingsColumns = () => edi.columns.get(USER_SIGNER_SETTINGS_COLUMN_CONFIG_NAME);

export { getUserSignerSettingsColumns, USER_SIGNER_SETTINGS_COLUMN_CONFIG_NAME };
