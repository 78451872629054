import { builder } from '@Components/orgSelector/orgSelectorInfoPanelTpl';

const superGetMainBlockTpl = builder.getMainBlockTpl;

builder.getMainBlockTpl = function (selectorInstance) {
	const arraySections = superGetMainBlockTpl(...arguments);
	const personSection =
		`<tpl elseif="$v.type === 'person'">` + builder.mainBlockSections.person.getTpl(selectorInstance).join(' ');
	arraySections.length = arraySections.length - 1;
	arraySections.push(personSection);
	arraySections.push('</tpl>');
	arraySections.push(...builder.mainBlockSections.person.inn());
	return arraySections;
};
builder.mainBlockSections.person = {
	getTpl: () => builder.mainBlockSections.person.firstLastPatronymicName(),
	firstLastPatronymicName: () => [
		'<tpl if="!Ext.isEmpty($v.person_lastname || $v.person_firstname || $v.person_patronymicname)">',
		'<div class="edi-company-name">',
		'	<span class="row-data edi-company-person-lastname">{person_lastname}</span>',
		'	<span class="row-data edi-company-person-firstname"> {person_firstname}</span>',
		'	<span class="row-data edi-company-person-patronymicname"> {person_patronymicname}</span>',
		'</div>',
		'</tpl>'
	],
	inn: () =>
		builder.createCodeValuesRow([
			{
				title: 'company.inn.short',
				name: 'person_inn',
				valueCls: 'edi-company-person-inn'
			}
		])
};
