// @ts-ignore
import { createTab } from '@UIkit/components/tab';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createCombo, createFieldsForProductGrid } from '@Components/fields';
import { createPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid, createToolBar } from '@Components/grid';
import { createAddButton, createSaveButton } from '@Components/buttons';
import { getUserSignerSettingsColumns } from './index';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS } from '@UIkit/components/buttons';
import { AuthorityAreasGridRecordData, AuthorityAreasProps } from '../../definitions';
import { SendRequestResponse } from '@App/js/definitions/request';
import { GridActionHandler, GridFilterObject } from '@App/js/definitions/grids';
import { getUserSignerSettingModelName } from './model';

export class AuthorityAreas {
	props: AuthorityAreasProps;
	filterObject: GridFilterObject | null;
	currentDocType: string | null;
	grid: ExtComponent;
	filterOptions: { id: string; name: string }[];
	userCertSettingsURL: string;
	filterForm: ExtComponent | null;

	constructor(props: AuthorityAreasProps, overrides?: Partial<AuthorityAreas>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: AuthorityAreasProps, overrides?: Partial<AuthorityAreas>) {
		const me = this;
		me.filterObject = null;
		me.currentDocType = null;
		me.userCertSettingsURL = edi.rest.services.USER_SELF_SIGNER_SETTINGS.GET;
		me.filterOptions = [
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD')
			},
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD')
			},
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
				name: edi.i18n.getMessage('documents.doctype.EDI_FNS_TORG2')
			},
			{
				id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
				name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD_SERVICE_AKT')
			}
		];
		me.filterForm = null;
		Ext.merge(me, overrides);
		me.props = props;
	}

	createSignerSettingModal(objectData?: AuthorityAreasGridRecordData) {
		const me = this;

		const authorityAreasFullStore = edi.stores.initAuthorityAreasFullStore();
		const statusesFullStore = edi.stores.initSignerBuyerStatusesUPD501NStore();

		authorityAreasFullStore.clearFilter(true);
		statusesFullStore.clearFilter(true);

		const updateAuthorityAndStatus = function (docType: string, direction: string) {
			if (!docType || !direction) {
				return;
			}

			const authorityAreaInput = form.down('[name=authorityArea]');
			const prevAuthorityAreaValue = authorityAreaInput.getValue();
			authorityAreasFullStore.loadData(
				Ext.clone(
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2AuthorityAreas[direction]
						: edi.stores.data.fullAuthorityAreas
				)
			);

			const areaIds: number[] = edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[docType][direction];
			authorityAreasFullStore.clearFilter(true);
			authorityAreasFullStore.filter({
				filterFn: function (item: ExtRecord<{ id: string; name: string }>) {
					return areaIds.some((it) => it === parseInt(item.get('id'), 10));
				}
			});
			// authorityAreasFullStore.reload();
			authorityAreaInput.setValue(prevAuthorityAreaValue);

			const statusInput = form.down('[name=state]');
			const prevStatusValue = statusInput.getValue();
			statusesFullStore.loadData(
				Ext.clone(
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2SignerStatuses[direction]
						: direction === 'OUTBOX'
						? edi.stores.data.signerBuyerUPD501NStatuses
						: edi.stores.data.signerBuyerStatuses
				)
			);

			const statusIds: number[] = edi.constants.SIGN_DOCUMENTS.STATUSES_FOR_DOC_TYPES[docType][direction];
			statusesFullStore.clearFilter(true);
			statusesFullStore.filter({
				filterFn: function (item: ExtRecord<{ id: string; name: string }>) {
					return statusIds.some((it) => it === parseInt(item.get('id'), 10));
				}
			});
			// statusesFullStore.reload();
			statusInput.setValue(prevStatusValue);
		};

		let isCreate = !objectData;
		const form = createModalForm({
			items: createFieldsForProductGrid(
				[
					{
						name: 'docType',
						title: edi.i18n.getMessage('document.type'),
						type: 'combo',
						allowBlank: false,
						store: edi.stores.createMemoryStore(me.filterOptions, 'SIMPLE'),
						value: null,
						listeners: {
							change: function (comp: ExtComponent, newValue: string) {
								const docType = newValue;
								const directionInput = form.down('[name=direction]');
								const directionValue = directionInput.getValue();
								updateAuthorityAndStatus(docType, directionValue);
							}
						}
					},
					{
						name: 'direction',
						type: 'combo',
						store: edi.stores.createMemoryStore(
							[
								{
									id: 'INBOX',
									name: edi.i18n.getMessage('user.profile.signer.direction.INBOX')
								},
								{
									id: 'OUTBOX',
									name: edi.i18n.getMessage('user.profile.signer.direction.OUTBOX')
								}
							],
							'SIMPLE',
							null
						),
						listeners: {
							change: function (comp: ExtComponent, newValue: string) {
								const direction = newValue;
								const docTypeInput = form.down('[name=docType]');
								const docTypeValue = docTypeInput.getValue();
								updateAuthorityAndStatus(docTypeValue, direction);
							}
						},
						allowBlank: false
					},
					{
						title: 'documents.fns_upd.signer.authorityArea',
						name: 'authorityArea',
						type: 'combo',
						store: authorityAreasFullStore,
						displayField: 'name'
					},
					{
						title: 'documents.fns_upd.signer.status',
						name: 'state',
						type: 'combo',
						store: statusesFullStore,
						listeners: {
							select: function (comp: ExtComponent, record: ExtRecord<{ id: string; name: string }>) {
								const stateRec = Array.isArray(record) ? record[0] : record;
								const state = stateRec.get('id');
								const baseAuthoryInput = form.down('[name=baseAuthory]');
								const baseOrgAuthoryInput = form.down('[name=baseOrgAuthory]');
								if (state == 4) {
									baseAuthoryInput.setValue(
										edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
									);
								} else {
									baseAuthoryInput.setValue(
										edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default')
									);
								}
								baseOrgAuthoryInput.allowBlank = state != 3;
								form.isValid();
							}
						},
						displayField: 'name'
					},
					{
						title: 'documents.fns_upd.signer.baseAuthory',
						maxLength: 255,
						name: 'baseAuthory'
					},
					{
						title: 'documents.fns_upd.signer.baseOrgAuthory',
						name: 'baseOrgAuthory',
						maxLength: 255,
						validator: function (value: string) {
							if (!value) {
								return true;
							}
							return (
								value === '' ||
								!edi.constants.VALIDATORS.SPACES.test(value) ||
								edi.i18n.getMessage('invalid.value.format')
							);
						}
					}
				],
				objectData
			)
		}) as ExtComponent;
		const modal = createModalPanel({
			title: edi.i18n.getMessage('user.profile.form.signer.settings'),
			width: MODAL_SIZE.widthMedium,
			items: [form],
			buttonsBefore: [
				createSaveButton(
					function () {
						const formValues = form.getValues(),
							gridStore = me.grid.getStore();
						let notSaved;

						gridStore.each(function (record: ExtRecord<AuthorityAreasGridRecordData>) {
							const recordData = record.getData();
							if (
								(isCreate || recordData.id != objectData?.id) &&
								formValues.direction === recordData.direction &&
								formValues.docType === recordData.docType
							) {
								notSaved = edi.core.showError(
									'user.profile.form.signer.settings.create.same.direction.alert'
								);
							}
						});
						if (!notSaved) {
							const saveData = form.getValues();
							edi.rest.sendRequest(
								isCreate
									? edi.rest.services.USER_SELF_SIGNER_SETTINGS.POST
									: edi.utils.formatString(edi.rest.services.USER_SELF_SIGNER_SETTINGS.PUT, {
											id: objectData!.id
									  }),
								isCreate ? 'POST' : 'PUT',
								Ext.encode(saveData),
								function (data: SendRequestResponse) {
									if (data.success) {
										gridStore.reload();
									}
								},
								edi.rest.getErrorHandler(),
								function () {
									modal.close();
									modal.setLoading(false);
								}
							);
						}
					},
					{
						cls: BUTTON_CLS.primary,
						bindToForm: form
					}
				)
			]
		});
		form.down('[name=docType]').setValue(me.currentDocType);
		modal.show();
	}

	fireSearch() {
		const me = this;
		me.filterObject?.filter();
	}

	createFilterObject() {
		const me = this;
		if (!me.filterObject) {
			me.filterObject = edi.filters.createGridFilter(
				me.userCertSettingsURL,
				me.filterForm,
				me.grid,
				function (values: { docType: string; gridName?: string }) {
					values = values || {};
					if (!values.gridName) {
						values.gridName = 'all';
					}
					return values;
				}
			);
		}
	}

	getColumns() {
		return getUserSignerSettingsColumns();
	}

	getActionColumns() {
		const me = this;
		const items: {
			glyph: string;
			handler: GridActionHandler<AuthorityAreasGridRecordData>;
		}[] = [];
		if (edi.permissions.hasPermission('CLIENT_UPDATE_EDI_SIGNER')) {
			items.push({
				glyph: edi.constants.ICONS.EDIT,
				handler: function (grid, rowIndex, colindex, actionItem, event, record) {
					me.createSignerSettingModal(record.getData());
				}
			});
		}
		if (edi.permissions.hasPermission('CLIENT_DELETE_EDI_SIGNER')) {
			const failure = edi.document.actions.defaultFailureHandler(
				me.props.moduleInstance.moduleData.tab,
				'error.server'
			);
			items.push({
				glyph: edi.constants.ICONS.DELETE,
				handler: function (grid, rowIndex) {
					edi.core.confirm(null, 'user.profile.form.signer.settings.delete.confirm', function () {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.USER_SELF_SIGNER_SETTINGS.DELETE,
								{
									id: grid.getStore().getAt(rowIndex).get('id')
								},
								false
							),
							'DELETE',
							null,
							undefined,
							failure,
							function () {
								me.grid.getStore().reload();
							}
						);
					});
				}
			});
		}
		return createActionsColumnConfig({
			items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	}

	getModel() {
		return getUserSignerSettingModelName();
	}

	getGridStoreConfig() {
		const me = this;
		return {
			model: me.getModel(),
			sortOnLoad: true,
			autoLoad: true,
			sorters: {
				property: 'creationDate',
				direction: 'DESC'
			}
		};
	}

	getGridCls(): string | string[] {
		return 'tab-grid';
	}

	getGridConfig() {
		const me = this;

		const columns = me.getColumns();
		columns.push(me.getActionColumns());

		return {
			cls: me.getGridCls(),
			region: 'center',
			border: 0,
			autoScroll: true,
			disableSelection: true,
			columns: columns,
			disablePaging: true,
			dockedItems: createToolBar({
				padding: 8,
				items: [
					createAddButton(() => me.createSignerSettingModal(), {
						cls: [BUTTON_CLS.primary]
					})
				]
			}),
			listeners: {
				celldblclick: function (
					view: ExtComponent,
					td: HTMLElement,
					cellIndex: number,
					record: ExtRecord<AuthorityAreasGridRecordData>
				) {
					me.createSignerSettingModal(record.getData());
				}
			}
		};
	}

	getGridViewConfig() {
		return {
			emptyTextTplOptions: {
				enabled: true,
				iconName: 'credentials',
				title: edi.i18n.getMessage('user.credentials.empty.text.tpl.title'),
				contentText: edi.i18n.getMessage('user.credentials.empty.text.tpl.contentText')
			}
		};
	}

	getGridProxyConfig() {
		const me = this;
		return {
			type: 'ajax',
			url: me.userCertSettingsURL
		};
	}

	createSignerSettingsGrid() {
		const me = this;

		me.grid = createGrid({
			storeConfig: me.getGridStoreConfig,
			gridConfig: me.getGridConfig(),
			viewConfig: me.getGridViewConfig(),
			proxyConfig: me.getGridProxyConfig()
		}) as ExtComponent;
		return me.grid;
	}

	createFilterForm() {
		const me = this;
		const formItemsMap = {
			docType: createCombo({
				name: 'docType',
				fieldLabel: edi.i18n.getMessage('document.type'),
				store: edi.stores.createMemoryStore(me.filterOptions, 'SIMPLE'),
				listeners: {
					select: function (cmp: ExtComponent, newValue: ExtRecord<{ id: string; name: string }>) {
						me.currentDocType = newValue ? newValue[0] : null;
					}
				}
			})
		};
		return (me.filterForm = createModuleFilterForm(
			{
				formItemsMap,
				items: [
					createPanel({
						layout: {
							type: 'grid',
							area: [[6]]
						},
						items: [formItemsMap.docType]
					})
				]
			},
			me.fireSearch.bind(me)
		) as ExtComponent);
	}

	createView() {
		const me = this;
		const filterForm = me.createFilterForm();
		const grid = me.createSignerSettingsGrid();
		me.createFilterObject();

		return [filterForm, grid];
	}

	createTab() {
		const me = this;
		const tabIndex = me.props.moduleInstance.tabCounter++;
		return createTab({
			title: edi.i18n.getMessage('user.profile.form.signer.settings'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			tabInstance: me,
			listeners: {
				activate: function () {
					me.props.moduleInstance.activeTab = tabIndex;
				}
			},
			autoScroll: false,
			items: me.createView()
		});
	}
}

export const createAuthorityAreasTab = function (
	props: AuthorityAreasProps,
	overrides?: Partial<AuthorityAreas>
): ExtComponent {
	const authorityAreas = new AuthorityAreas(props, overrides);
	return authorityAreas.createTab() as ExtComponent;
};
