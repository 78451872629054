// @ts-ignore
import { createTab } from '@UIkit/components/tab';
import { createActionsColumnConfig, createCheckboxSelectionModel, createGrid, createToolBar } from '@Components/grid';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';
import { createLabel } from '@Components/fields';
import { getSimpleNameColumns, getUserCertificatesColumns } from './columns';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createAddButton, createDeleteButton } from '@Components/buttons';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createCertificatesFormItems } from './filters';
import { CertificateHandler, SignProcess } from '@App/js/signProcess';
import { CertificatesGridRecordData, CertificatesTabProps } from '../../definitions';
import { SendRequestResponse } from '@App/js/definitions/request';
import { Cert } from '@App/js/definitions/cert';
import { GridActionHandler, GridFilterObject } from '@App/js/definitions/grids';

export class Certificates {
	filterObject: GridFilterObject | null;
	props: CertificatesTabProps;
	certificatesURL: string;
	defaultFailureHandler: (data: SendRequestResponse) => void;
	grid: ExtComponent | null;
	idToOpenOnReload: number | null;
	lastAddedRowId: number | null;
	filterForm: ExtComponent | null;

	constructor(props: CertificatesTabProps, overrides?: Partial<Certificates>) {
		const me = this;
		me.modifyConfig(props, overrides);
	}

	modifyConfig(props: CertificatesTabProps, overrides?: Partial<Certificates>) {
		const me = this;
		me.filterObject = null;
		me.idToOpenOnReload = null;
		me.lastAddedRowId = null;
		me.grid = null;
		me.filterForm = null;
		me.certificatesURL = edi.rest.services.USER.SELF.CERTIFICATE.LIST.GET;
		Ext.merge(me, overrides);
		me.props = props;
		me.defaultFailureHandler = edi.document.actions.defaultFailureHandler(
			me.props.moduleInstance.moduleData.tab,
			'error.server'
		);
	}

	deleteCertificate(id: number, callback: (data: SendRequestResponse) => any) {
		const me = this;
		me.props.moduleInstance.setLoading(true);
		const postData = {
			[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE]: true
		};
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: id
				},
				true
			),
			'PUT',
			Ext.encode(postData),
			function () {
				me.props.moduleInstance.setLoading(false);
				edi.events.certificate.fireEvent('change');
			},
			edi.document.actions.defaultFailureHandler(
				me.props.moduleInstance.moduleData.tab,
				'document.error.in.delete.process'
			),
			callback
		);
	}

	pushPB(id: number, callback: (data: SendRequestResponse) => any, dontOpen?: boolean) {
		const me = this;
		me.props.moduleInstance.setLoading(true);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: id
				},
				true
			),
			'PUT',
			Ext.encode({}),
			function () {
				me.props.moduleInstance.setLoading(false);
				!dontOpen ? (me.idToOpenOnReload = id) : null;
				edi.events.certificate.fireEvent('change');
			},
			me.defaultFailureHandler,
			callback
		);
	}

	rejectCert(id: number, callback: (data: SendRequestResponse) => any) {
		const me = this;
		me.props.moduleInstance.setLoading(true);
		const postData = {
			[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT]: true,
			[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON]: ''
		};
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: id
				},
				true
			),
			'PUT',
			Ext.encode(postData),
			function () {
				me.props.moduleInstance.setLoading(false);
				me.idToOpenOnReload = id;
				edi.events.certificate.fireEvent('change');
			},
			me.defaultFailureHandler,
			callback
		);
	}

	signCert(recordData: CertificatesGridRecordData, modal: ExtComponent, cert: Cert) {
		const me = this;
		edi.utils.sign(
			recordData,
			modal,
			function (failed: boolean, data: SendRequestResponse) {
				if (failed) {
					me.defaultFailureHandler(data);
				} else {
					modal.close();
					edi.events.certificate.fireEvent('change');
				}
			},
			undefined,
			undefined,
			undefined,
			{
				_selectedCertificate: cert,
				get: function () {
					return this._selectedCertificate;
				},
				set: function (certificate: CertificateHandler['_selectedCertificate']) {
					this._selectedCertificate = certificate;
				}
			},
			{ disabledPoa: true }
		);
	}

	showModalCertificateDetails(recordData: CertificatesGridRecordData) {
		const me = this;

		let buttons: ExtComponent[] = [];
		const getModal = function () {
			return modal;
		};
		me.props.moduleInstance.setLoading(false);
		if (recordData.state === edi.constants.STATE.REJECTED) {
			buttons.push(
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('document.convert.to.draft.document'),
					glyph: edi.constants.ICONS.DRAFT,
					handler: function () {
						edi.core.confirm(null, 'user.profile.certificate.draft.confirmation', function () {
							modal.setLoading();
							me.pushPB(recordData.id, function () {
								modal.close();
							});
						});
					}
				}) as ExtComponent
			);
		} else if (recordData.state === edi.constants.STATE.DRAFT) {
			buttons.push(
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('document.sign.document'),
					glyph: edi.constants.ICONS.SIGN,
					handler: function () {
						edi.core.confirm(null, 'user.profile.certificate.sign.confirmation', function () {
							if (edi.sign.isAvailable()) {
								edi.sign.getCertificates({
									callback: function (data: { data: Cert[]; error: any }) {
										let cert;
										if (data.data) {
											cert = edi.utils.findObjectInArray(
												data.data,
												'Thumbprint',
												recordData.Thumbprint
											);
										}
										if (cert) {
											me.signCert(recordData, modal, cert);
										} else {
											edi.utils.async(edi.core.showError('user.profile.certificate.not.found'));
										}
									}
								});
							} else {
								edi.sign.displayNotAvailableMessage();
							}
						});
					}
				}) as ExtComponent
			);
			buttons.push(
				createDeleteButton(
					function () {
						edi.core.confirm(null, 'user.profile.certificate.remove.confirmation', function () {
							modal.setLoading();
							me.deleteCertificate(recordData.id, function () {
								modal.close();
							});
						});
					},
					{
						disabled: false
					}
				) as ExtComponent
			);
		} else if (recordData.state === edi.constants.STATE.CONFIRMED) {
			buttons.push(
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('form.btn.activate'),
					glyph: edi.constants.ICONS.DONE,
					handler: function () {
						const crossingSerts: ExtRecord<CertificatesGridRecordData>[] = [];
						me.grid?.getStore().findBy(function (record: ExtRecord<CertificatesGridRecordData>) {
							if (record.get('state') === 'ACTIVE' && record.get('id') != recordData.id) {
								if (
									record.get('availableDocTypesForSignature')[0] === 'ALL' ||
									recordData.availableDocTypesForSignature[0] === 'ALL'
								) {
									crossingSerts.push(record);
								} else {
									for (let j = 0; j < recordData.availableDocTypesForSignature.length; j++) {
										if (
											record
												.get('availableDocTypesForSignature')
												.some(
													(it: string) => it === recordData.availableDocTypesForSignature[j]
												)
										) {
											crossingSerts.push(record);
											break;
										}
									}
								}
							}
						});
						if (crossingSerts.length) {
							const modalConfirm = createModalPanel({
								title: edi.i18n.getMessage('documents.flow.rule.modal.title'),
								width: MODAL_SIZE.widthLarge,
								items: [
									createModalForm({
										items: [
											createLabel({
												text: edi.i18n.getMessage(
													'user.profile.certificate.activate.confirmation'
												)
											})
										]
									}),
									createGrid({
										store: edi.stores.createMemoryStore(crossingSerts, 'USER_CERTIFICATE'),
										gridConfig: {
											columns: getUserCertificatesColumns(),
											disablePaging: true
										}
									})
								],
								buttons: [
									createButton({
										cls: BUTTON_CLS.primary,
										text: edi.i18n.getMessage('button.yes'),
										glyph: edi.constants.ICONS.DONE,
										handler: function () {
											modal.setLoading();
											me.pushPB(
												recordData.id,
												function () {
													modalConfirm.close();
													modal.close();
												},
												true
											);
										}
									}),
									createButton({
										cls: BUTTON_CLS.secondary,
										text: edi.i18n.getMessage('button.no'),
										glyph: edi.constants.ICONS.CANCEL,
										handler: function () {
											modalConfirm.close();
										}
									})
								]
							});
							modalConfirm.show();
						} else {
							me.pushPB(
								recordData.id,
								function () {
									modal.close();
								},
								true
							);
						}
					}
				}) as ExtComponent
			);
		} else if (recordData.state === edi.constants.STATE.ACTIVE) {
			//МЧД
			if (edi.methods.poa && 'function' == typeof edi.methods.poa.getButtonUserProfile) {
				const poaBtn = edi.methods.poa.getButtonUserProfile(getModal, recordData);
				buttons = [...poaBtn, ...buttons];
			}

			buttons.push(
				createButton({
					cls: BUTTON_CLS.secondary,
					text: edi.i18n.getMessage('form.btn.deactivate'),
					glyph: edi.constants.ICONS.REJECT,
					handler: function () {
						edi.core.confirm(null, 'user.profile.certificate.deactivate.confirmation', function () {
							modal.setLoading();
							me.pushPB(
								recordData.id,
								function () {
									modal.close();
								},
								true
							);
						});
					}
				}) as ExtComponent
			);
		}
		if (
			recordData.state === edi.constants.STATE.WAIT_SIGNATURE ||
			recordData.state === edi.constants.STATE.REVIEW ||
			recordData.state === edi.constants.STATE.CONFIRMED
		) {
			buttons.push(
				createButton({
					cls: BUTTON_CLS.secondary,
					text: edi.i18n.getMessage('document.reject.document'),
					glyph: edi.constants.ICONS.REJECT,
					handler: function () {
						edi.core.confirm(null, 'user.profile.certificate.reject.confirmation', function () {
							modal.setLoading();
							me.rejectCert(recordData.id, function () {
								modal.close();
							});
						});
					}
				}) as ExtComponent
			);
		}
		const modal = edi.methods.certificate.details(recordData, buttons) as ExtComponent;
	}

	fireSearch() {
		const me = this;
		me.filterObject?.filter();
	}

	createFilterObject() {
		const me = this;
		if (!me.filterObject) {
			me.filterObject = edi.filters.createGridFilter(
				me.certificatesURL,
				me.filterForm,
				me.grid,
				function (values: { gridName?: string }) {
					values = values || {};
					if (!values.gridName) {
						values.gridName = 'without_deactivated';
					}
					return values;
				}
			);
		}
	}

	getColumns() {
		return getUserCertificatesColumns();
	}

	getActionColumns() {
		const me = this;
		const items: {
			glyph: string;
			handler: GridActionHandler<CertificatesGridRecordData>;
		}[] = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				handler: function (grid, rowIndex, colindex, actionItem, event, record) {
					me.showModalCertificateDetails(record.getData());
				}
			}
		];
		return createActionsColumnConfig({
			items,
			width: edi.utils.getActionColumnWidth(items.length)
		});
	}

	getModel() {
		return edi.models.getModel('USER_CERTIFICATE');
	}

	selectCertificate(availableDocTypesForSignature: string[]) {
		const me = this;

		if (!edi.sign.isAvailable()) {
			edi.sign.displayNotAvailableMessage();
		} else {
			const signContent = new Date().getTime();
			edi.utils.sign(
				undefined,
				me.props.moduleInstance.moduleData.tab,
				function (failed: boolean, data: SendRequestResponse) {
					me.props.moduleInstance.setLoading(false);
					if (failed) {
						edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
					} else {
						me.idToOpenOnReload = data && data.data ? data.data.id : null;
						me.lastAddedRowId = me.idToOpenOnReload;
						edi.events.certificate.fireEvent('change');
					}
				},
				undefined,
				undefined,
				false,
				undefined,
				{
					signUrl: edi.rest.services.USER.SELF.CERTIFICATE.POST,
					signContent: edi.utils.base64.encode(signContent),
					signObjectProcessor: function (signature: SignProcess['_signedContent']) {
						return {
							sign: signature,
							content: signContent,
							availableDocTypesForSignature: availableDocTypesForSignature || []
						};
					},
					signUrlMethod: 'POST',
					doNotUseDefaultCert: true,
					disabledPoa: true
				}
			);
		}
	}

	getGridStoreConfig() {
		const me = this;
		return {
			model: me.getModel(),
			sortOnLoad: true,
			autoLoad: false,
			sorters: {
				property: 'creationDate',
				direction: 'DESC'
			},
			listeners: {
				load: function (store: ExtBase, records: ExtRecord<CertificatesGridRecordData>[], successul: Boolean) {
					if (successul) {
						let toOpen: CertificatesGridRecordData | undefined;
						if (me.idToOpenOnReload && records.length) {
							let record;
							for (let i = 0; i < records.length; i++) {
								record = records[i];
								if (record.get('id') == me.idToOpenOnReload) {
									toOpen = record.getData();
									break;
								}
							}
						}
						me.idToOpenOnReload = null;
						if (toOpen) {
							if (toOpen.state === edi.constants.STATE.DRAFT && me.lastAddedRowId) {
								const crossingSerts: ExtRecord<CertificatesGridRecordData>[] = [];

								me.lastAddedRowId = null;
								me.grid?.getStore().findBy(function (record: ExtRecord<CertificatesGridRecordData>) {
									if (record.get('id') != toOpen!.id) {
										if (
											record.get('availableDocTypesForSignature')[0] === 'ALL' ||
											toOpen!.availableDocTypesForSignature[0] === 'ALL'
										) {
											crossingSerts.push(record);
										} else {
											for (let j = 0; j < toOpen!.availableDocTypesForSignature.length; j++) {
												if (
													record
														.get('availableDocTypesForSignature')
														.some(
															(it: string) =>
																it === toOpen!.availableDocTypesForSignature[j]
														)
												) {
													crossingSerts.push(record);
													break;
												}
											}
										}
									}
								});

								if (crossingSerts.length) {
									const modalConfirm = createModalPanel({
										title: edi.i18n.getMessage('documents.flow.rule.modal.title'),
										width: MODAL_SIZE.widthLarge,
										closable: false,
										items: [
											createModalForm({
												items: [
													createLabel({
														text: edi.i18n.getMessage(
															'user.profile.certificate.activate.confirmation'
														)
													})
												]
											}),
											createGrid({
												store: edi.stores.createMemoryStore(crossingSerts, 'USER_CERTIFICATE'),
												gridConfig: {
													columns: getUserCertificatesColumns(),
													disablePaging: true
												}
											})
										],
										buttons: [
											createButton({
												cls: BUTTON_CLS.primary,
												text: edi.i18n.getMessage('button.ok'),
												glyph: edi.constants.ICONS.DONE,
												handler: function () {
													modalConfirm.close();
													me.showModalCertificateDetails(toOpen!);
												}
											})
										]
									});
									modalConfirm.show();
								}
							} else {
								me.showModalCertificateDetails(toOpen);
							}
						}
					}
				}
			}
		};
	}

	getGridCls(): string | string[] {
		return 'tab-grid with-filter';
	}

	getGridConfig() {
		const me = this;

		const columns = me.getColumns();
		columns.push(me.getActionColumns());

		return {
			cls: me.getGridCls(),
			columns: columns,
			region: 'center',
			border: 0,
			autoScroll: true,
			disableSelection: true,
			dockedItems: createToolBar({
				padding: 8,
				items: [
					createAddButton(
						() => {
							edi.core.confirm(
								null,
								'sign.all.certificate.confirmation',
								function () {
									me.selectCertificate(['ALL']);
								},
								function () {
									let selectionModel: ExtBase;
									const gridData = [];
									for (let i = 0; i < edi.constants.SIGN_DOCUMENT_TYPES.length; i++) {
										const docType = edi.constants.SIGN_DOCUMENT_TYPES[i];
										gridData.push({
											id: docType,
											name: edi.i18n.getMessage('documents.doctype.' + docType)
										});
									}
									const grid = createGrid({
										store: edi.stores.createInlineStore(gridData),
										gridConfig: {
											columns: getSimpleNameColumns(),
											height: 400,
											selModel: (selectionModel = createCheckboxSelectionModel({})),
											disablePaging: true
										}
									});
									const modal = createModalPanel({
										items: [grid],
										width: MODAL_SIZE.widthSmall,
										title: edi.i18n.getMessage('sign.document.types.selection'),
										buttonsBefore: [
											createButton({
												cls: BUTTON_CLS.primary,
												text: edi.i18n.getMessage('form.btn.select'),
												glyph: edi.constants.ICONS.DONE,
												handler: function () {
													const selected = selectionModel.getSelection();
													const docTypes = [];
													for (let i = 0; i < selected.length; i++) {
														docTypes.push(selected[i].get('id'));
													}
													me.selectCertificate(docTypes);
													modal.close();
												}
											})
										]
									});
									modal.show();
								}
							);
						},
						{
							cls: [BUTTON_CLS.primary]
						}
					)
				]
			}),
			listeners: {
				celldblclick: function (
					view: ExtComponent,
					td: HTMLElement,
					cellIndex: number,
					record: ExtRecord<CertificatesGridRecordData>
				) {
					me.showModalCertificateDetails(record.getData());
				}
			}
		};
	}

	getGridViewConfig() {
		const me = this;
		return {
			emptyTextTplOptions: {
				enabled: true,
				iconName: 'certificates',
				title: edi.i18n.getMessage('user.certificates.empty.text.tpl.title'),
				contentText: edi.i18n.getMessage('user.certificates.empty.text.tpl.contentText'),
				beforeButtonText: edi.i18n.getMessage('user.certificates.empty.text.tpl.beforeButtonText'),
				buttonText: edi.i18n.getMessage('user.certificates.empty.text.tpl.buttonText'),
				buttonName: 'emptyTextButton',
				buttonClickHandler: function () {
					me.filterForm?.toggleHandler();
				}
			}
		};
	}

	getGridProxyConfig() {
		const me = this;
		return {
			type: 'ajax',
			url: me.certificatesURL
		};
	}

	createCertificatesGrid() {
		const me = this;

		me.grid = createGrid({
			storeConfig: me.getGridStoreConfig(),
			gridConfig: me.getGridConfig(),
			viewConfig: me.getGridViewConfig(),
			proxyConfig: me.getGridProxyConfig()
		}) as ExtComponent;

		return me.grid;
	}

	createFilterForm() {
		const me = this;
		const { formItemsMap, items } = createCertificatesFormItems();
		return (me.filterForm = createModuleFilterForm(
			{
				formItemsMap,
				items
			},
			me.fireSearch.bind(me)
		) as ExtComponent);
	}

	createView() {
		const me = this;
		const filterForm = me.createFilterForm();
		const grid = me.createCertificatesGrid();
		me.createFilterObject();

		return [filterForm, grid];
	}

	createTab() {
		const me = this;
		const tabIndex = me.props.moduleInstance.tabCounter++;
		return createTab({
			title: edi.i18n.getMessage('user.certificates.title'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			tabInstance: me,
			listeners: {
				activate: function () {
					me.props.moduleInstance.activeTab = tabIndex;
				},
				render: function () {
					me.fireSearch();
				}
			},
			autoScroll: false,
			items: me.createView()
		});
	}
}

export const createCertificatesTab = function (
	props: CertificatesTabProps,
	overrides?: Partial<Certificates>
): ExtComponent {
	const certificates = new Certificates(props, overrides);
	return certificates.createTab() as ExtComponent;
};
