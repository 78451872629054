// @ts-ignore
import { createCheckbox } from '@UIkit/components/fields';
// @ts-ignore
import { createContainer } from '@UIkit/components/panels';

const createCertificatesFormItems = () => {
	const formItemsMap = {
		gridName: createCheckbox({
			boxLabel: edi.i18n.getMessage('deactivated.only'),
			text: edi.i18n.getMessage('deactivated.only'),
			name: 'gridName',
			inputValue: 'deactivated',
			getChipValue(fld: ExtComponent) {
				return (fld.getValue() && fld.boxLabel) || '';
			}
		})
	};

	const items = [
		createContainer({
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [formItemsMap.gridName]
		})
	];

	return {
		formItemsMap,
		items
	};
};

export { createCertificatesFormItems };
